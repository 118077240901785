/* eslint-disable array-callback-return */
import React, { useState } from 'react';
import RCSumDropDownSelectBranch from '../../../RCSumTable/RCSumDropDownSelectBranch';
import TableShowItemUser from '../TableShowItemUser';
import TableShowUserMain from '../TableShowUserMain';
import TabShowItemUserBlock from '../TableShowItemUser/TabShowItemUserBlock';

ManagerAllUser.propTypes = {
};


function ManagerAllUser(props) {
    const { data, target } = props;
    const listAreAddMore = [...props.listArea]

    // console.log("vo here Manager ALl User")

    const [isActive, setIsActive] = useState(false);
    const [item, setItem] = useState("");
    function onOpenActionModal(item) {
        setIsActive(true);
        setItem(item);
    }

    return (
        <React.Fragment>
            <div className="p-3 manager-user_branch">
                <div className="d-flex">
                    <h3>Select Area</h3>
                    <RCSumDropDownSelectBranch
                        onSelect={props.changeSelectArea}
                        //    onblur={props.setSelectArea}
                        selectValue={props.selectArea}
                        listRender={listAreAddMore}
                    />
                    <button className="btn btn-primary"
                        onClick={props.loadData}>
                        Load Data
                    </button>
                </div>
                {data.length > 0 ?
                    <div className="manager-user_user rp mt-3">
                        <div style={{
                            position: "relative",
                        }}>
                            <div className={isActive ? "" : "op-hidden"}
                                style={{
                                    position: "absolute",
                                    width: '90%',
                                    backgroundColor: 'blanchedalmond',
                                    margin: 'auto',
                                    height: '500px',
                                    left: '5%',
                                    borderRadius: '20px',
                                    top: '75px'
                                }}>
                                <TableShowUserMain
                                    item={item}
                                    target = {target}
                                    setIsActive={setIsActive}
                                    data={item.other ? item.other.login : { listBlock: [], listActivated: [] }}
                                    updateNewPassToServer={props.updateNewPassToServer}
                                    onChangeActionGlist={props.onChangeActionGlist}
                                    onChangeNoteGlist={props.onChangeNoteGlist}
                                    onUpdateNoteToServer={props.onUpdateNoteToServer}
                                />
                            </div>

{
    target === "listBlockDatabase" ? <TabShowItemUserBlock 
        data={data}
        onOpenActionModal={onOpenActionModal}
        />: 
        <TableShowItemUser
        data={data}
        onOpenActionModal={onOpenActionModal}
/>
}
                            
                        </div>
                    </div>
                    : <h1>Null data</h1>}
            </div>
        </React.Fragment >
    );
}

export default ManagerAllUser;



